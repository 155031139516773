import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"

import { GatsbyImage } from "gatsby-plugin-image"
import ReactHtmlParser, { processNodes } from "react-html-parser"

import SectionHeader from "../components/SectionHeader"

import {
  Header3,
  Header6,
  Body,
  Bold,
  Caption,
  Header5,
  Label,
} from "../components/Typography"

import styled from "styled-components"
import Button from "../components/Button"
import { FiArrowLeft } from "react-icons/fi"
import { Helmet } from "react-helmet"

const StyledA = styled.a`
  color: var(--primary100);
  text-decoration: none;
  font-weight: 500;

  :hover {
    text-decoration: underline;
  }
`

const StyledBody = styled(Body)`
  color: var(--base100);
  margin-bottom: 12px;
`

const ArticleContainer = styled.div`
  max-width: 636px;
  padding: 0 16px;
  margin: 0 auto;
  display: grid;
  grid-auto-flow: row;
`

const ButtonBackRow = styled.div`
  padding: 40px 0;
  margin-left: -20px;
  justify-self: start;
`

const BlogPostDetails = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  gap: 16px;
  align-items: center;
  padding: 0 0 16px 0;
  justify-self: start;
`

const BlogHeader = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 8px;
`

const StyledHeader6 = styled(Header6)`
  margin-bottom: 12px;
  margin-top: 16px;
`

const StyledGatsbyImage = styled(GatsbyImage)`
  margin: 40px 0;
`

const Category = styled(Caption)`
  background: var(--primary015);
  justify-self: start;
  padding: 5px 10px;
  border-radius: 2px;
  color: var(--base100);
`

const AuthorImage = styled(GatsbyImage)`
  width: 32px;
  height: 32px;
`
const AuthorImageBio = styled(GatsbyImage)`
  width: 74px;
  height: 74px;
`

const AuthorContainer = styled(Link)`
  display: inline-grid;
  grid-auto-flow: column;
  gap: 8px;
  align-items: center;
`

const AuthorBioContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 32px;
  margin: 0;
  padding: 100px 0 40px 0px;
`

const AuthorBio = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 32px;
`

const AuthorName = styled(Link)`
  :hover h5 {
    color: var(--primary100);
  }
`

const ArticleTemplate = ({ data, pageContext }) => {
  const article = data.allNodeBlogs.edges[0].node

  const author =
    article.relationships.field_blog_author.display_name === "daniel"
      ? "Daniel Papp"
      : "Arnie Papp"

  const authorImageData =
    author === "Daniel Papp"
      ? data.daniel.edges[0].node.localFile.childImageSharp.gatsbyImageData
      : data.arnie.edges[0].node.localFile.childImageSharp.gatsbyImageData

  const authorBlurb =
    author === "Daniel Papp"
      ? "Hi, I’m Daniel, the COO of CueBack. I love dogs (especially mine), whiskey, and Muay Thai. I enjoy reading anything, including nutritional information, higher-ed dissertations, and machine learning research papers. If you’d like to chat, connect with me on LinkedIn and send me a message!"
      : "Hi, I’m Arnie, the CEO of CueBack. I love life, family, traveling, and writing."

  const authorPosition =
    author === "Daniel Papp" ? "CueBack COO" : "CueBack CEO"

  const formatCreated = new Date(article.created).toISOString()
  const formatChanged = new Date(article.changed).toISOString()

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Article",
    headline: article.title,
    image: [
      encodeURI(article.relationships.field_blog_image.localFile.publicURL),
    ],
    datePublished: formatCreated,
    dateModified: formatChanged,
    author: [
      {
        "@type": "Person",
        name: author,
        url: "/about/" + article.relationships.field_blog_author.display_name,
      },
    ],
  }

  let article_body_elements = new ReactHtmlParser(article.body.value, {
    transform: function transform(node) {
      if (node.type === "tag" && node.name === "img") {
        let uuid = node.attribs["data-entity-uuid"]
        let i = 0

        for (i = 0; i < data.allFileFile.edges.length; i++) {
          if (
            data.allFileFile.edges[i].node.drupal_id === uuid &&
            data.allFileFile.edges[i].node.localFile
          ) {
            return (
              <StyledGatsbyImage
                image={
                  data.allFileFile.edges[i].node.localFile.childImageSharp
                    .gatsbyImageData
                }
                backgroundColor="#fff"
              />
            )
          }
        }
      } else if (node.type === "tag" && node.name === "figure") {
        let source = node.children[0].attribs["src"]
        let name = decodeURI(
          source.substring(source.lastIndexOf("/") + 1, source.length)
        )

        let i = 0
        for (i = 0; i < data.allFileFile.edges.length; i++) {
          if (
            data.allFileFile.edges[i].node.filename === name &&
            data.allFileFile.edges[i].node.localFile
          ) {
            return (
              <StyledGatsbyImage
                image={
                  data.allFileFile.edges[i].node.localFile.childImageSharp
                    .gatsbyImageData
                }
                backgroundColor="#fff"
              />
            )
          }
        }
      } else if (node.type === "tag" && node.name === "p") {
        if (processNodes(node.children, transform)[0] === " ") {
          return null
        } else {
          return (
            <StyledBody>{processNodes(node.children, transform)}</StyledBody>
          )
        }
      } else if (
        node.type === "tag" &&
        node.name === "a" &&
        node.attribs.href.includes("cueback.com/blog")
      ) {
        var url = node.attribs.href.replace("https://www.cueback.com", "")
        return <Link to={url}>{processNodes(node.children, transform)}</Link>
      } else if (node.type === "tag" && node.name === "a") {
        var path = node.attribs.href
        return (
          <StyledA target="_blank" href={path}>
            {processNodes(node.children, transform)}
          </StyledA>
        )
      } else if (node.type === "tag" && node.name === "strong") {
        return <Bold>{processNodes(node.children, transform)}</Bold>
      } else if (node.type === "tag" && node.name === "h2") {
        return (
          <StyledHeader6>
            {processNodes(node.children, transform)}
          </StyledHeader6>
        )
      }
      return undefined
    },
  })
  let article_component = (
    <Layout
      pageTitle={article.title}
      pageDescription={article.field_description}

      image={`https://www.cueback.com/og-image${article.path.alias}.png`}
    >
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
      </Helmet>
      <ArticleContainer>
        <ButtonBackRow>
          <Button url="/blog">
            <FiArrowLeft /> Back to Blog
          </Button>
        </ButtonBackRow>
        <BlogHeader>
          <Category>{article.relationships.field_category[0].name}</Category>
          <Header3>{article.title}</Header3>
          {article.field_blog_subtitle ? (
            <Body>{article.field_blog_subtitle}</Body>
          ) : (
            ""
          )}
          <BlogPostDetails>
            <AuthorContainer to="#author">
              <AuthorImage
                image={authorImageData}
                imgStyle={{ borderRadius: 2 }}
              />
              <Bold style={{ color: "var(--primary100)" }}>{author}</Bold>
            </AuthorContainer>
            <Caption>{article.created}</Caption>
          </BlogPostDetails>
        </BlogHeader>
        {article_body_elements}
        <AuthorBioContainer id="author">
          <SectionHeader>About the Author</SectionHeader>
          <AuthorBio>
            <AuthorImageBio
              image={authorImageData}
              imgStyle={{ borderRadius: 2 }}
            />
            <div>
              <AuthorName
                to={
                  "/about/" +
                  article.relationships.field_blog_author.display_name
                }
              >
                <Header5
                  style={{
                    marginBottom: 4,
                  }}
                >
                  {author}
                </Header5>
              </AuthorName>
              <Label
                style={{
                  display: "block",
                  marginBottom: 16,
                }}
              >
                {authorPosition}
              </Label>
              <Label style={{ display: "block" }}>{authorBlurb}</Label>
            </div>
          </AuthorBio>
        </AuthorBioContainer>
      </ArticleContainer>
    </Layout>
  )
  return article_component
}

export const query = graphql`
  query ($id: String!) {
    allNodeBlogs(filter: { id: { eq: $id } }) {
      edges {
        node {
          title
          id
          field_blog_subtitle
          field_description
          created(formatString: "MMMM DD, YYYY")
          changed(formatString: "MMMM DD, YYYY")
          body {
            value
          }
          path {
            alias
          }
          relationships {
            field_category {
              name
            }
            field_blog_image {
              localFile {
                publicURL
              }
            }
            field_blog_author {
              display_name
            }
          }
        }
      }
    }
    allFileFile(
      filter: {
        filemime: { regex: "/image/", nin: "image/gif" }
        uri: { url: { regex: "/inline-images/" } }
      }
      sort: { fields: drupal_internal__fid }
    ) {
      edges {
        node {
          drupal_id
          filename
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    arnie: allFileFile(filter: { uri: { url: { regex: "/arnie/" } } }) {
      edges {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: CONSTRAINED, width: 100)
            }
          }
        }
      }
    }
    daniel: allFileFile(filter: { uri: { url: { regex: "/daniel-1/" } } }) {
      edges {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: CONSTRAINED, width: 100)
            }
          }
        }
      }
    }
  }
`

export default ArticleTemplate
